import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { MainLayout } from "../../components";
import { AdditionalSettingsInterest } from "./components/addition-settings-interest";
import { FlexBox, GridBox } from "../../components/wilson-ui/box";
import { Settings } from "./components/setting-interest";
import { AddInterestsTrendingSchema } from "./add-interests-trending-schema";
import { AddInterestsOtherSchema } from "./add-interests-other-schema";
import { useCreateWidgetsStyles } from "./create-widgets-style.hook";
import ROUTING_CONFIG from "../../constants/routing-config/routing-config";
import { initialValuesInterest } from "./initeal-values";
import { sendFormHelper } from "./helpers/send-interest";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { InterestTypeEnum } from "../../enum";
import { UpgradeInterestToEditHelper } from "../../helpers/widgets/upgrade-widget-to-edit.helper";
import { Spiner } from "../../components/wilson-ui/spiner";
import { getInterest } from "../../actions/usersInterests";
import { usePageStyles } from "../pages-styles.hook";

export const CreateUsersInterestsPage: React.FC = () => {
  const intl = useIntl();
  const [currentInterest, setCurrentInterest] = useState({ name: '' });
  const [isFound, setIsFound] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstRenderEnd, setIsFirstRenderEnd] = useState(false);
  const [widgetsSchema, setWidgetsSchema] = useState<Yup.ObjectSchema<any>>();
  const classes = useCreateWidgetsStyles();
  const classesTitle = usePageStyles();
  const history = useHistory();
  const location: Record<string, any> = useLocation();

  const form = useFormik({
    initialValues: initialValuesInterest,
    validationSchema: widgetsSchema,
    onSubmit: () => {
      sendFormHandler();
    },
  });

  useEffect(() => {
    getWidgetHandler();
  }, []);

  useEffect(() => {
    if (form.values.type) {
      if (form.values.type.toUpperCase() === InterestTypeEnum.OTHER) {
        setWidgetsSchema(AddInterestsOtherSchema);
      } else {
        setWidgetsSchema(AddInterestsTrendingSchema);
      }
    }

    if (isFirstRenderEnd) {
      form.setValues(form.values);
    } else {
      setIsFirstRenderEnd(true);
    }

  }, [form.values.type]);

  const notify = (isExists: boolean) => toast(intl.formatMessage({ id: isExists ? "error_interest_exists": "something_is_wrong" }));

  const sendFormHandler = async () => {
    const payload: any = { ...form.values };
    const id = location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (id && form.values.name === currentInterest?.name) {
      payload.name = undefined;
    }
    const res = await sendFormHelper(payload);
    const data = res.data;
    if (data.interest) {
      history.push(ROUTING_CONFIG.userInterests);
    } else if (data.isExists) {
      notify(data.isExists);
    } else notify(false);
  };

  const getWidgetHandler = async () => {
    const id = location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (id) {
      const data = await getInterest(id);

      if (data.id) {
        const widgetToSet: any = UpgradeInterestToEditHelper(data);
        setCurrentInterest(widgetToSet);
        form.setValues(widgetToSet);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsFound(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const body = isFound ? (
    isLoading ? (
      <Spiner />
    ) : (
      <>
        <GridBox columns="auto 312px" className={classes.container} cg="50px">
          <div>
            <h2 className={classesTitle.pageTitle}>
              {intl.formatMessage({ id: "interests_details" })}
            </h2>
            <Settings {...{ form }} />
          </div>
          <AdditionalSettingsInterest {...{ form }} />
        </GridBox>
      </>
    )
  ) : (
    <FlexBox items="center" content="center">
      <h3 className={classes.errorTitle}>
        {intl.formatMessage({ id: "interest_not_found" })}
      </h3>
    </FlexBox>
  );

  return <MainLayout>{body}</MainLayout>;
};

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment-timezone";
import { usersTableConfig } from "./users-table-config";
import { sortValue } from "../../types";
import UsersStore from "../../store/users/user.store";
import { MainLayout } from "../../components";
import { LayoutWrap } from "../../components/wrappers/layout-wrap/layout-wrap";
import { UsersHeader } from "./components/users-header";
import { UiTable } from "../../components/wilson-ui/table";
import { Spiner } from "../../components/wilson-ui/spiner";
import { CopyItem } from "../../components/items";
import { NameItem } from "../../components/items";
import { EMPTY_FUNCTION } from "../../constants";
import { RadioCheckboxRed } from "../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { FlexBox } from "../../components/wilson-ui/box";
import {ConfirmModal} from "../submissions/components/confirm-modal";
import {useWinnerItemStyles} from "./user-actins-button-styles.hook";
import UserStore from "../../store/users/user.store";
import {removeUser} from "../../actions/users";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const UsersPage: React.FC = observer(() => {
  const [term, setTerm] = useState<string>('');
  const [rows, setRows] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sortByName, setSortByName] = useState<string>("id");
  const [order, setOrder] = useState<string>("ASC");
  const [isModalShow, setIsModalShow] = useState(false);
  const [userChosen, setUserChosen] = useState<string | undefined>();

  const toggleModal = (userId?: string) => {
    setIsModalShow(!isModalShow)
    setUserChosen(userId);
  };

  const styles = useWinnerItemStyles();

  const removeUserHandler = async () => {
    if (!userChosen) {
      return;
    }

    UserStore.isLoading = true;
    await removeUser(userChosen);
    setTerm('');
    await UsersStore.usersRequest(rows, 1, order, sortByName, '');

    toggleModal();
  };

  const users = UsersStore.getUsers();

  useEffect(() => {
    !UsersStore.isLoading &&
      UsersStore.usersRequest(rows, page, order, sortByName, term);
  }, [rows, page, order, sortByName, term]);

  useEffect(() => {
    EMPTY_FUNCTION();
    return () => UsersStore.setUsers({ data: [], length: 0, lengthAll: 0 });
  }, []);

  const setSearch = (val: string) => {
    setTerm(val);
    UsersStore.usersRequest(rows, page, order, sortByName, val);
  };

  const setSortStateHandler = (value: string) => {
    if (value === sortByName)
      setOrder(Object.values(sortValue).filter((item) => item !== order)[0]);
    setSortByName(value);
  };

  const setRowPerPageHandler = (val: number) => {
    setPage(1);
    setRows(val);
  };

  const components = {
    data: (row: Record<string, any>, col: string) => (
      <span>{row[col] ? moment(row[col]).format("MMMM Do YYYY, HH:mm"): 'null'}</span>
    ),
    id: (row: Record<string, any>, col: string) => (
      <CopyItem maxLengthProps={5} row={row} col={col} />
    ),
    email: (row: Record<string, any>, col: string) => (
      <CopyItem maxLengthProps={20} row={row} col={col} />
    ),
    name: (row: Record<string, any>) => (
      <NameItem name={`${row.firstName} ${row.lastName}`} />
    ),
    checkbox: (row: Record<string, any>, col: string) => (
      <RadioCheckboxRed
        label={""}
        value={row[col]}
        submit={() => {
          switch (col) {
            case "isWhitelisted":
              return UsersStore.changeUserWhitelist(row["id"], !row[col]);
            case "explicitSms":
              return UsersStore.changeUserExplicitSMS(row["id"], !row[col]);
            default:
              return;
          }
        }}
        name={col}
      />
    ),
    button: (row: any) => (
        // isDev() &&
        <FlexBox items="center" gap="8px">
          <div className={styles.container}>
            <DeleteForeverIcon onClick={() => toggleModal(row.id)} className={styles.icon} />
          </div>
        </FlexBox>
    ),
  };

  const table =
    UsersStore.isLoading && users.data.length < 1 ? (
      <Spiner />
    ) : (
      <UiTable
        sortSubmit={setSortStateHandler}
        components={components}
        config={usersTableConfig()}
        data={users.data}
        setRowPerPage={setRowPerPageHandler}
        rowPerPage={rows}
        page={page}
        setPage={setPage}
        length={users.length}
        setSearch={setSearch}
        searchValue={term}
        searchTable
      />
    );

  return (
    <MainLayout>
      <UsersHeader
        order={order}
        page={page}
        rows={rows}
        length={users.lengthAll}
        sortByName={sortByName}
        term={term}
      />
      <LayoutWrap minHeight="400px">{table}</LayoutWrap>
      {isModalShow && (
          <ConfirmModal
              toggleModal={toggleModal}
              submit={removeUserHandler}
              title="Are you sure?"
          />
      )}
    </MainLayout>
  );
});

export const status: any = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

export const statusValue: any = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE'
}

export const categories: any = [
  'Basketball',
  'Football',
  'Racquet',
  'Baseball',
  'Golf',
  'Volleyball',
  'Sportswear',
  'Collabs & Limited Edition'
];

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import ROUTING_CONFIG from "../../../../constants/routing-config/routing-config";
import { useAdditionalSettingsStyles } from "./additional-settings-styles.hook";
import { AdditionalItem } from "../additional-item";
import { status, statusValue, categories } from "./contants";
import { FormikSelect} from "../../../../components/wilson-ui/selects";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { RadioboxRed } from "../../../../components/wilson-ui/checkboxes";
import { InternalStatus } from "../../../../types";
import { removeUserInterests } from "../../../../actions/usersInterests";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { ConfirmModal } from "../../../submissions/components/confirm-modal";
import UserInterestsStore from "../../../../store/userInterests/userInterests.store";
import { InterestTypeEnum } from "../../../../enum";

interface IAdditionalSettings {
  form: Record<string, any>;
}

export const AdditionalSettingsInterest: React.FC<IAdditionalSettings> = ({ form }) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isDeleteWidgetModalShow, setIsDeleteWidgetModalShow] = useState(false);
  const toggleModal = () =>
    setIsDeleteWidgetModalShow(!isDeleteWidgetModalShow);
  const [offset, setOffset] = useState<number>(0);
  const classes = useAdditionalSettingsStyles();
  const intl = useIntl();
  const divRef = React.useRef<HTMLDivElement>(null);
  const fixedBlockClass = offset > 80 ? classes.containerOffsetActive : "";
  // const offsetLeft = divRef.current ? divRef.current.offsetLeft : "auto";

  const logit = () => {
    setOffset(window.scrollY);
  };

  const notifyAllField = () => {
    console.log("form.errors", form.errors);
    // console.log("form.values", form.values);
    const formErrorsArray = Object.values(form.errors);

    if (form.values.type === InterestTypeEnum.OTHER && !formErrorsArray.includes('mediaUrl') && formErrorsArray.length === 1) {
      return false;
    }

    if (formErrorsArray.length) {
      toast(
        intl.formatMessage({ id: "please_fill_in_all_required_fields" })
      );
      return true;
    }
  };

  const submitHandler = () => {
    let check: any = false;
    if (form.touched && !form.isValid) check = notifyAllField();
    if (!check) form.handleSubmit();
  };

  const removeWidgetHandler = async (id: string) => {
    UserInterestsStore.isLoading = true;
    await removeUserInterests(id);
    UserInterestsStore.isLoading = false;
    history.push(ROUTING_CONFIG.userInterests);
    toggleModal();
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  const onSelectStatus = (value: any) => {
    const option = value.target;
    form.setValues({ ...form.values, status: statusValue[option.value] });
  }

  return (
    <div ref={divRef}>
      <div
        className={`${fixedBlockClass} ${classes.container}`}
      >
        <AdditionalItem title={intl.formatMessage({ id: "status" })}>
          <FormikSelect
            {...{ form }}
            name="status"
            handleChange={onSelectStatus}
            value={form.values.status ? status[form.values.status]: ''}
            options={Object.values(InternalStatus)}
            placeholder={intl.formatMessage({ id: "status" })}
          />
        </AdditionalItem>
      </div>
      <div
        className={`${fixedBlockClass} ${classes.container}`}
        // style={{ left: offsetLeft }}
      >
        <AdditionalItem title={intl.formatMessage({ id: "categories" })}>
          <FlexBox direction="column" gap="10px">
            {categories.map((category: string) => (
              <FlexBox key={category} items="center" gap="10px" draggable={true}>
                <RadioboxRed 
                  onChange={() => form.setFieldValue('category', category)} 
                  checked={form.values.category === category} 
                />
                <span>{category}</span>
              </FlexBox>
            ))}
          </FlexBox>
        </AdditionalItem>
        {form.errors?.category ? (
          <div className={classes.errorText}>
            {intl.formatMessage({ id: form.errors?.category.toLowerCase()})}
          </div>
        ): null}
      </div>
      <GridBox columns="1fr 2fr" cg="10px">
        <UiButton
          type="button"
          buttonType="white"
          onClick={toggleModal}
          disabled={form.isSubmitting || !id}
          className={classes.deleteButton}
        >
          {intl.formatMessage({ id: "delete" })}
        </UiButton>
        <UiButton
          buttonType="black"
          onClick={submitHandler}
          disabled={form.isSubmitting}
          className={classes.saveButton}
        >
          {intl.formatMessage({ id: "save" })}
        </UiButton>
      </GridBox>
      {isDeleteWidgetModalShow && (
        <ConfirmModal
          toggleModal={toggleModal}
          submit={() => removeWidgetHandler(id)}
          title="Are you sure?"
        />
      )}
    </div>
  );
};

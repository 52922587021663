import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useUsersDownloadCSV } from "../../hooks";
import { usePageStyles } from "../../../pages-styles.hook";
import { Text } from "../../../../components/wilson-ui/text";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { DownloadCsvModal } from "../../../../components/wilson-ui/modals";

interface IProps {
  rows: number;
  page: number;
  sortByName: string;
  order: string;
  length: number;
  term?: string;
}

export const UsersHeader: React.FC<IProps> = ({
  rows,
  page,
  sortByName,
  order,
  term,
  length
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCSV, setCurrentCSV] = useState([]);
  const [fullCSV, setFullCSV] = useState([]);
  const pageClasses = usePageStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const downloadCSVHandler = () =>
    useUsersDownloadCSV(
      rows,
      page,
      length,
      order,
      sortByName,
      term,
      setCurrentCSV,
      setFullCSV,
      toggleModal
    );

  const modal = isModalOpen && (
    <DownloadCsvModal
      closeHandler={toggleModal}
      currentCSV={currentCSV}
      fullCSV={fullCSV}
      currentName="users-current-list"
      fullName="users-full-list"
    />
  );

  return (
    <FlexBox className={pageClasses.container}>
      <Text className={pageClasses.pageTitle}>
        {intl.formatMessage({ id: "users" })}
      </Text>
      <UiButton buttonType="white" onClick={downloadCSVHandler}>
        {intl.formatMessage({ id: "export" })}
      </UiButton>
      {modal}
    </FlexBox>
  );
};

import { Dispatch } from "react";
import {
  downloadUserFullListCSV,
  downloadUsersCSV,
} from "../../../../actions/users";
import { EMPTY_FUNCTION } from "../../../../constants";

export const useUsersDownloadCSV = async (
  rows: number,
  page: number,
  length: number,
  order: string,
  sortByName: string,
  term: string | undefined,
  setCurrentCSV: Dispatch<any>,
  setFullCSV: Dispatch<any>,
  toggleModal: () => void
): Promise<void> => {
  try {
    const CSVFile = await downloadUsersCSV(rows, page, order, sortByName, term);
    const CSVFileFullList = await downloadUserFullListCSV(length);
    setCurrentCSV(CSVFile);
    setFullCSV(CSVFileFullList);
    toggleModal();
  } catch (error) {
    EMPTY_FUNCTION();
  }
};
